.MobileFooterNavbarLayout{
    display: flex;
    width: 100%;
    height: 10%;
    justify-content: space-evenly;
    align-items: center;
}

.MobileNavbarButton{
    width: 100%;
    height: 100%;
}