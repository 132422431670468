.mobileWizardPageLayout {
  // padding: 40px 0px;
  padding-top: 40px;
  padding-bottom: 80px;
  height: 100%;
}

.mobileWizardPageLayoutPreferences{
  height: 90%;
  padding-top: 40px;
}

.stepBody {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
}

.stepBodyTest {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 115%;
  overflow: hidden;
}
