.mobileAppLayout {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;
  background-color: #061237;
  color: #fdfdfd;
}

.table-bottom {
  background-color: #343647;
  div,
  svg {
    background-color: #343647;
    color: #b6b7ba;
  }
}
