.customedInput{
    background: #454758;
    color: #B6B7BA !important;
}

.outlinedCustomInput{
    border-radius: 24px !important;
}

.buttonGoogleStyles{
 display: 'flex';
 justify-content: center;
}

.customedInputDescription{
    background: #454758;
    color: #B6B7BA !important;
    border-radius: 12px !important;
}

.borderRadiusInput{
    border-radius: 24px !important;
}