.styledTabs {
  margin-top: 20px;
  width: 100%;
  &.muitabs-indicator {
    background-color: #1890ff;
  }
}

.fixtureTableCell {
  min-width: 0 !important;
  color: #fafafa !important;
  margin: 0 !important;
}

.hover-fixture-tile:hover {
  cursor: pointer;
}
