.cardProfile{
    width: 100%;
    height: 100%;
}

.upload-button{
    margin: 20px;
    padding: 10px;
    // border: 3px #0d6efd solid;
    color: #111;
    background: #F9BF4B;
    border-radius: 8px;
    font-weight: 600;
    
}