.inputAutocompLeague {
  #league-panel-search-label {
    color: white;
  }

  #league-panel-search {
    color: white;
  }

  button {
    color: white;
  }
}

div [role="presentation"] {
  li {
    background: #454758 !important;
    color: #fafafa !important;
    border-bottom: 1px solid #cacaca;
  }

  div {
    background: #454758 !important;
    color: #fafafa !important;
  }

  ul {
    background: #454758 !important;
    padding-bottom: 0;
    margin-bottom: 0;
  }
}

.hoverIcon:hover {
  cursor: pointer;
}
